import { useCallback, useEffect, useState } from 'react';
import { Content, Row, Box, Col, SimpleTable, Button } from 'adminlte-2-react';
import { callApi } from 'CallAPI';
import ClickableDatatables from '../../components/ClickableDatatables';

import UserInfoType from '../../modules/UserInfoType';
import ReactDOMServer from 'react-dom/server';
import base64 from 'base-64';
import ReactCountryFlag from 'react-country-flag'
import Formatters from '../../utils/Formatters';
import './User.css';
import memoizee from 'memoizee';
import { iconReactText } from './Users';

const dataPreRenderIp = memoizee((ip, geoIp) => {
    const flag = memoizee(igeoip => ReactDOMServer.renderToStaticMarkup(<ReactCountryFlag className="country-flag" countryCode={igeoip ? igeoip : 'xx'} svg />));
    return flag(geoIp) + '<span>' + ip + '</span>';
});


function parseUserResult(resultado: number) {
    const resultadoRelations = {
        '0': 'OK',
        '-1': 'INVALID_USER',
        '-2': 'INVALID_PASS',
        '-3': 'OTHER_SESSION',
        '-4': 'BLOCKED',
        '-5': 'CLOCK_DIFF',
    }
    return resultadoRelations[resultado];
}

const basicUserInfo: {
    title: string;
    data: string;
    render?: any;
}[] =
    [
        { title: 'ID', data: 'ID' },
        { title: 'Login', data: 'user_login' },
        { title: 'NiceName', data: 'user_nicename' },
        { title: 'DisplayName', data: 'display_name' },
        { title: 'Email', data: 'user_email' },
        { title: 'Registered', data: 'user_registered', render: Formatters.date },

        { title: 'Some Order', data: 'hs_ord', render: (data) => iconReactText(data, 'Yes', 'No') },
        { title: 'Order Completed', data: 'ordcmplt', render: (data) => iconReactText(data, 'Yes', 'No') },
        { title: 'Launcher', data: 'usedLauncher', render: (data) => iconReactText(data, 'Yes', 'No') },
        { title: 'Online', data: 'isOnline', render: (data) => iconReactText(data, 'Online', 'Offline') },
        { title: 'Last Ping', data: 'lastPingTime', render: Formatters.date },
    ];

const loginTbColumns: DataTables.ColumnSettings[] =
    [
        {
            title: 'ID',
            data: 'id'
        },
        {
            title: 'Result',
            data: { 
                _: row => row.resultado,
                display: row => parseUserResult(row.resultado),
                filter: row => parseUserResult(row.resultado),
            }
        },
        {
            title: 'IP',
            data: {
                _: row => row.ip,
                display: row => dataPreRenderIp(row.ip, row.geoIp)
            },
            className: 'geoip-ip'
        },
        {
            title: 'Metric',
            data: {
                _: row => row.metric,
                display: row => '<span class="label label-success">Click To Get</span>'
            },
            className: 'horizon-center'
        },
        {
            title: 'Main Drive Serial',
            data: 'main_drive_serial'
        },
        {
            title: 'Date',
            data: {
                _: row => row.date,
                display: row => Formatters.date(row.date)
            }
        },
        {
            title: 'Version',
            data: 'version'
        },
    ];

const exTbColumns: DataTables.ColumnSettings[] =
    [
        {
            title: 'ID',
            data: 'id'
        },
        //{ title: 'Token', data: 'token' },
        {
            title: 'Last User',
            data: 'last_user'
        },
        {
            title: 'Date',
            data: {
                _: row => row.date,
                display: row => Formatters.date(row.date)
            }
        },
        {
            title: 'Original Date',
            data: {
                _: row => row.original_date,
                display: row => Formatters.date(row.original_date)
            }
        },
        {
            title: 'IP',
            data: {
                _: row => row.ip,
                display: row => dataPreRenderIp(row.ip, row.geoIp)
            },
            className: 'geoip-ip'
        },
        {
            title: 'EX Stack',
            data: 'ex_stack'
        },
        {
            title: 'Extra Data',
            data: {
                _: row => row.extra_data,
                display: ({ extra_data }) => (extra_data && isNaN(extra_data) ? base64.decode(extra_data) : extra_data)
            }
        },
        {
            title: 'Last Error',
            data: 'last_error'
        },
        {
            title: 'Source',
            data: 'source'
        },
        {
            title: 'Version',
            data: 'version'
        },
    ];


const User = (props) => {
    const params = props.match.params;

    const [userInfo, setUserInfo] = useState<UserInfoType.RootObject>({} as UserInfoType.RootObject);
    const [internalDisableRUV, setInternalDisableRUV] = useState(false);
    const computeGetUser = useCallback(async () => {
        try {
            const response = await callApi('getUser', { id: params['id'] }) as UserInfoType.RootObject;
            console.log(response);
            setUserInfo(response);
        } catch (error) {
            console.log(error);
        }
    }, [params]);
    useEffect(() => { computeGetUser(); }, [computeGetUser]);

    async function onResetUserValidantionClick() {
        try {
            const response = await callApi('ignoreNextUserValidationToken', { id: params['id'] });
            console.log(response);
            if (response) {
                setInternalDisableRUV(true);
                window.alert('Resetado com sucesso');
            } else {
                window.alert('Erro ao resetar');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return <Content title='User' subTitle='Manage user' browserTitle='User'>

        <Box collapsable title='Basic Info' border type='info' loaded={Object.keys(userInfo).length}>
            {!!userInfo.fullUserInfo && <SimpleTable
                columns={basicUserInfo}
                data={[userInfo.fullUserInfo]}
            />}
        </Box>

        <Box collapsable collapsed title='User last Computer login info' border type='warning' loaded={Object.keys(userInfo).length}>
            {!userInfo.metricParsed ? null : Object.keys(userInfo.metricParsed).map((value, index) => {
                const thisValue = userInfo.metricParsed[value];

                if (thisValue[0]) {
                    const firstItemKeys = Object.keys(thisValue[0]);
                    const nowColumns = firstItemKeys.map((valuea) => {
                        return { title: valuea, data: valuea };
                    });
                    return (
                        <div key={index}>
                            <h3 style={{ 'marginTop': '33px' }}> {value} </h3>
                            <SimpleTable
                                columns={nowColumns}
                                data={thisValue}
                            />
                        </div>
                    );
                } else {
                    return null;
                }
            })}
        </Box>

        <Box collapsable title='Last user logins' border type='success' loaded={Object.keys(userInfo).length}>
            <ClickableDatatables
                columns={loginTbColumns}
                data={userInfo.userLogin}
                history={props.history}
                redirectBaseUrl='/metric/'
                rowClickName='id'
                options={{
                    lengthChange: true,
                    iDisplayLength: 10,
                }} />
        </Box>
        <Box collapsable title='Exceptions' border type='danger' loaded={Object.keys(userInfo).length}>
            <ClickableDatatables
                columns={exTbColumns}
                data={userInfo.userExceptions}
                history={props.history}
                redirectBaseUrl='/exception/'
                rowClickName='id'
                options={{
                    lengthChange: true,
                    iDisplayLength: 10,
                    scrollX: true,
                    scrollCollapse: true,
                }} />
        </Box>

        <Box solid title='Comandos' border type='warning' loaded={Object.keys(userInfo).length}>
            <Button
                disabled={userInfo?.fullUserInfo?.isSerialIgnored || internalDisableRUV}
                text='Resetar token de validação'
                type='primary'
                margin
                onClick={onResetUserValidantionClick}
            />
            <Button
                text='Crashar PC do usuario BSOD'
                type='danger'
                margin
                onClick={() => window.alert('Não seja essa pessoa má :(')}
            />
        </Box>

        {/* <Box collapsable title='User Sessions' border type='info' loaded={Object.keys(userInfo).length}>
        </Box> */}

    </Content>;
}



export default User;