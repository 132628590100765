import { Modal, Button } from 'react-bootstrap'

function MModal({ show, handleCancel, handleOK, title, children }: { show: boolean, handleCancel: () => void, handleOK: () => void, title: string, children: any }) {
    const myHandleCancel = () => {        
        handleCancel();
    }
    const myHandleOK = () => {
        handleOK();
    }
    return (
        <Modal
            show={show}
            onHide={handleCancel}
            backdrop='static'
            dialogClassName='modal-warning'
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={myHandleCancel}>
                    CANCEL
                </Button>
                <Button variant="primary" onClick={myHandleOK}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MModal;