import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Infobox } from 'adminlte-2-react';
import { callApi, callApiSocket } from 'CallAPI';
import { useCallback, useEffect, useState } from 'react';

const data = [
    {
        icon: 'fa-signal',
        color: 'red',
        number: '...',
        text: 'Nada ainda',
    },
    {
        icon: 'fa-plug',
        color: 'yellow',
        number: '...',
        text: 'Nada ainda',
    }];

const InfoBoxs = (props) => {
    const [allUsersCount, setAllUsersCount] = useState<any>('...');
    const [onlineUsersCount, setOnlineUsersCount] = useState<any>('...');
    const [onlineUsersCountExtra, setOnlineUsersCountExtra] = useState<any>('');
    let dynamicOnlineUsersCount = 0;

    const startEffect = useCallback(async () => {
        try {
            const response = await callApi('getUsersAndOnlineCount', {});
            console.log(response);
            setAllUsersCount(response.allUsersCount);
        } catch (error) {
            console.log(error);
        }

        try {
            const response = await callApiSocket('liveUserCount', { time: 500 }, (data) => {                
                const userCountDiff = data - dynamicOnlineUsersCount;                
                if (!dynamicOnlineUsersCount) {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    dynamicOnlineUsersCount = data;
                }
                setOnlineUsersCount(data);
                setOnlineUsersCountExtra(
                    <span className={`description-percentage text-${userCountDiff > 0 ? 'green' : userCountDiff < 0 ? 'red' : 'blue'}`}>
                        {Math.abs(userCountDiff)}
                        {' '}
                        <FontAwesomeIcon icon={['fas', `caret-${userCountDiff > 0 ? 'up' : userCountDiff < 0 ? 'down' : 'left'}` as any]} />                       
                    </span>
                );
            });
            setOnlineUsersCountExtra('');
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }, []);
    useEffect(() => { startEffect(); }, [startEffect]);
    return (
        <>
            <Col xs={12} sm={6} md={3}>
                <Infobox text='Usuarios' icon='fas-users' number={allUsersCount} color='green' />
            </Col>
            <Col xs={12} sm={6} md={3}>
                <Infobox text='Usuarios Online' icon='fas-chalkboard-teacher' number={
                    <>
                        {onlineUsersCount}
                        {' '}
                        {onlineUsersCountExtra}
                    </>
                } color='aqua' />
            </Col>


            {/* <Infobox text='Pedidos concluidos' /> */}

            {data.map((props, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col key={`upperInfoBox${index}`} xs={12} sm={6} md={3}>
                    <Infobox {...props} />
                </Col>
            ))}
        </>
    );

};


export default InfoBoxs;