// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _React from 'react';
import classNames from 'classnames';
import './ProgressBar.css';

const ProgressBar = ({ barPercent = 0, text = '', id = '' }) => {
    if (barPercent > 100) barPercent = 100;
    return (
        <div id={id} className={classNames('pbprogress', 'pbprogress--active', {'pbprogress--complete': barPercent === 100})}>
            <b style={{ 'width': barPercent + '%' }} className={classNames({
                'pbprogress__bar': true,
                'pbprogress__bar--green': barPercent >= 85,
                'pbprogress__bar--yellow': barPercent >= 45,
                'pbprogress__bar--orange': barPercent >= 30,        
                'pbprogress__bar--blue': barPercent === 100
            })}>
                <span className="pbprogress__text" >
                    {text}
                </span>
            </b>
        </div>);    
};

export default ProgressBar;