/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useEffect, useRef } from 'react';
import $ from 'jquery';

import 'glyphicons-only-bootstrap/css/bootstrap.min.css'
import 'datatables/media/css/jquery.dataTables.min.css'
import './ClickableDatatables.css'
import Formatters from '../utils/Formatters';

($ as any).DataTable = require('datatables.net-bs');

const ClickableDatatables = (props: {
    data: Array<any>,
    columns: Array<any>,
    customColumnsType?: { [key: number]: string | ((data: any) => string) } | undefined,
    options?: any,
    history: any,
    redirectBaseUrl: string,
    rowClickName: string,
    ignoreEmpty?: boolean,
    extraColumnDefs?: Array<DataTables.ColumnDefsSettings>,
}) => {

    const tbtb = useRef<HTMLTableElement>(null);

    const registerClickHandler = useCallback(() => {
        if (!props.ignoreEmpty && (!props.data || !props.data.length)) return;
        if (!tbtb.current) return;

        const $tbt = $(tbtb.current);

        if (!$.fn.dataTable.isDataTable($tbt)) {
            const table = $tbt.DataTable({
                data: props.data,
                columns: props.columns,
                keys: true,
                info: true,
                autoWidth: true,
                select: false,
                paging: true,
                searching: true,
                lengthChange: true,
                iDisplayLength: 50,
                aLengthMenu: [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, "All"]],
                ordering: true,
                deferRender: true,
                order: [[0, "desc"]],
                columnDefs: ([
                    {
                        targets: Object.keys(props.columns).map(key => parseInt(key)),
                        render: (data, type, row, meta) => {
                            //console.log(data, type, row, meta);
                            if (type === 'display') {
                                let finalData = data;
                                if (props.customColumnsType) {
                                    switch (props.customColumnsType[meta.col]) {
                                        case 'date':                                        
                                            finalData = Formatters.date(data);
                                            break;
                                        default:
                                            if (props.customColumnsType[meta.col] instanceof Function) 
                                                finalData = (props.customColumnsType[meta.col] as (data: any) => string)(data);
                                            break;
                                    }
                                }
                                data = `<a class="clickable-table-a" onclick="event.preventDefault();" href="${props.redirectBaseUrl + row[props.rowClickName]}">${finalData}</a>`;
                            }
                            return data;
                        }
                    }
                ] as DataTables.ColumnDefsSettings[]).concat(props.extraColumnDefs ? props.extraColumnDefs : []),
                ...props.options
            });
    
            $tbt.on('dblclick', 'tbody td', (event) => {
                const $currentTarget = $(event.currentTarget);
                if ($currentTarget.children('.clickable-table-a').length > 0) {
                    const rowData = table.row($currentTarget).data();
                    props.history.push(props.redirectBaseUrl + rowData[props.rowClickName]);
                }
            })
        }

    }, [props.ignoreEmpty, props.data, props.columns, props.extraColumnDefs, props.options, props.customColumnsType, props.redirectBaseUrl, props.rowClickName, props.history]);

    useEffect(() => { registerClickHandler(); }, [registerClickHandler]);

    const classes = [
        'table',
        true ? 'table-hover' : null,
        false ? 'table-bordered' : null,
        false ? 'table-condensed' : null,
        'responsive',
        'nowrap',
        'cell-border',
        'ClickableDatatablestb'
    ].join(' ');

    return <table ref={tbtb} className={classes} />;
}


export default ClickableDatatables;