import { Row, Col, Inputs } from 'adminlte-2-react';
import { Formik, Form as FormikForm, Field } from 'formik';
import PropTypes from 'prop-types';

const { Text } = Inputs;

const LoginCore = ({ children }) => (
    <div className='login-box'>
        <div className='login-logo'>
            <a href='/' id='paheader'>
                <b>Painel</b>Eldorado
      </a>
        </div>
        <div className='login-box-body' style={{background: '#a1a1a1'}}>
            {children}
        </div>
    </div>
);

LoginCore.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

LoginCore.defaultProps = {
    children: null,
};



const LoginForm = ({ handleSubmit, initialValues }) => {
    return (
        <div id='login-div'>
            <Row>
                <Col xs={12} md={12}>
                    <LoginCore>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                        >
                            <FormikForm className='Form'>
                                <Field
                                    name='user'
                                    label='Usuário'
                                    labelPosition='above'
                                    placeholder='Pedro'
                                    iconRight='fas-envelope'
                                    component={InputValidation}
                                />
                                <Field
                                    type='password'
                                    name='password'
                                    label='Senha'
                                    labelPosition='above'
                                    placeholder='12345678'
                                    iconRight='fas-lock'
                                    component={InputValidation}
                                />
                                <button
                                    type='submit'
                                    className='btn btn-success btn-block'
                                    id='loginButton'
                                >
                                    Entrar
                                </button>
    
                            </FormikForm>
                        </Formik>
                    </LoginCore>
                </Col>
            </Row>
        </div>
    );
};

LoginForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired
};

const InputValidation = ({
    className,
    placeholder,
    label,
    labelPosition,
    iconLeft,
    iconRight,
    field,
    form,
    value,
    type
}) => {
    const onChange = valor => {
        form.setFieldValue(field.name, valor.target.value);
    };

    const onBlur = e => {
        const { handleBlur } = form;
        handleBlur(e);
    };

    const getValue = () => {
        return value;
    };

    return (
        <Text
            inputType={type}
            className={className}
            name={field.name}
            value={getValue()}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            label={label}
            labelPosition={labelPosition}
            iconLeft={iconLeft}
            iconRight={iconRight}
        />
    );
};

export default LoginForm;