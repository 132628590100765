/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useEffect, useState } from 'react';
import { Content, Row, Box, Col, LoadingSpinner } from 'adminlte-2-react';
import { callApi } from '../../CallAPI';

import ClickableDatatables from '../../components/ClickableDatatables';

import './Users.css'
import Formatters from 'utils/Formatters';
import ReactDOMServer from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import memoizee from 'memoizee';
import InfoBoxs from '../../components/InfoBoxs';

export const iconReactText = (bol: boolean, textTrue, textFalse) => (
    <span>
        <FontAwesomeIcon icon={['fas', 'circle']} className={`text-${bol ? 'success' : 'danger'}`} />
        {' ' + (bol ? textTrue : textFalse)}
    </span>
);

export const preRenderIcon = memoizee((bol: boolean, textTrue, textFalse) => {
    return ReactDOMServer.renderToStaticMarkup(iconReactText(bol, textTrue, textFalse));
});

function dataWrapperIcon(data, textTrue, textFalse) {
    return {
        _: row => !!(row[data]),
        display: row => preRenderIcon(row[data], textTrue, textFalse)
    };
}

const firstColumns: DataTables.ColumnSettings[] =
    [
        {
            title: 'ID',
            data: 'ID'
        },
        {
            title: 'Login',
            data: 'user_login'
        },
        {
            title: 'Email',
            data: 'user_email'
        },
        {
            title: 'Buy Completed',
            data: dataWrapperIcon('ordcmplt', 'Sim', 'Não')
        },
        {
            title: 'Used Launcher',
            data: dataWrapperIcon('used', 'Sim', 'Não')
        },
        {
            title: 'Online',
            data: dataWrapperIcon('online', 'Online', 'Offline')
        },
        {
            title: 'Registered',
            data: {
                _: row => row.user_registered,
                display: row => Formatters.date(row.user_registered)
            }
        }
    ];


let gUserList = [];

const Users = (props) => {
    const [dataModList, setdataModList] = useState<any>([]);

    const startEffect = useCallback(async () => {
        try {
            if (!gUserList.length) {
                gUserList = await callApi('listUsers', {});
            }
            setdataModList(gUserList);
        } catch (error) {
            console.log(error);
        }
    }, []);
    useEffect(() => { startEffect(); }, [startEffect]);


    return <Content title='Users' subTitle='See all users' browserTitle='Users'>
        <Row>
            <InfoBoxs />
            <Col xs={12} >                
                <Box /* loaded={dataModList.length} */>
                    {!dataModList.length && <LoadingSpinner />}
                    <ClickableDatatables
                        columns={firstColumns}
                        data={dataModList}
                        history={props.history}
                        redirectBaseUrl='/user/'
                        rowClickName='ID'
                    /* extraColumnDefs={[{
                        targets: [3],
                        createdCell: (td, cellData, rowData, row, col) => {
                            console.log(td, cellData);
                        }
                    }]} */
                    />
                    {dataModList.length ? null :
                        <div style={{ height: '100px' }}>
                        </div>}
                </Box>
            </Col>
        </Row>
    </Content>;
}


export default Users;