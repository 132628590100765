import axios, { AxiosResponse } from 'axios';
import io from 'socket.io-client';

let gGetTokenFunction: () => (string | null) | null;
let gClearTokenFunction: () => void | null;

export function configCallApi(getTokenFunction: () => (string | null) | null, clearTokenFunction: () => (void) | null) {
    gGetTokenFunction = getTokenFunction;
    gClearTokenFunction = clearTokenFunction;
}

const SERVER_API_LOCATION = 'https://adminapi.mapaeldorado.com.br:18302'

const axiosinst = axios.create({
    baseURL: SERVER_API_LOCATION,
    headers: {
        'Content-type': 'application/json'
    }
});

function checkResponseError(response: AxiosResponse<any>) {
    if (response.status === 200) return response.data;
    if (response.status === 401) gClearTokenFunction();
    throw response.data;
}

export async function callApi(endpoint: string, data: any, formData: FormData = new FormData(), onUploadProgress: ((progressEvent: any) => void) | undefined = undefined): Promise<any> {
    const adminToken = gGetTokenFunction();
    let extraPanel = {};
    if (data) extraPanel = data;
    if (adminToken) extraPanel = { ...extraPanel, ...{ adminToken: adminToken } };
    try {
        const response = await axiosinst.post('/admin/' + endpoint, formData, {
            headers: {
                'extra-panel': JSON.stringify(extraPanel),
                ...!!formData ? { 'Content-Type': 'multipart/form-data' } : {}
            },
            onUploadProgress
        });
        return checkResponseError(response);
    } catch (error: any) {
        if (error.response?.status === 401) gClearTokenFunction();
        else {
            const beutyError = (error.response?.data?.message) ? error.response.data.message : error;
            window.alert(beutyError);
            throw error;
        }        
    }
}

export async function callApiSocket(endpoint: string, data: any, someData: ((data: any) => void)): Promise<any> {
    const adminToken = gGetTokenFunction();

    const socket = io(SERVER_API_LOCATION, {
        transports: ['websocket'],
        secure: true,
        rejectUnauthorized: true,
        auth: {
            tk: adminToken,
            path: endpoint,
            data: data
        }
    });

    return new Promise<void>((resolve, reject) => {
        function abc (eventName: any, errOrData: any = undefined) {
            switch (eventName) {                
                case 'connect':
                    console.log('connected');
                    break;
                case 'data':
                    someData(errOrData)
                    break;
                case 'disconnect':
                    reject(eventName);
                    break;
                case 'error':
                case 'zerror':
                case 'connect_error':
                    reject(errOrData);
                    break;
                case 'finished':
                    resolve();
                    break;
                default:
                    console.log(eventName);
                    break;
            }
        }

        socket.on('connect', () => abc('connect'));
        socket.on('disconnect', () => abc('disconnect'));
        socket.on('error', (err) => abc('error', err));
        socket.on('zerror', (err) => abc('zerror', err));
        socket.on('connect_error', (err) => abc('connect_error', err));        
        socket.onAny(abc);
    });
}
