import moment from 'moment';

const Formatters = {
    speed: function(speedBytes) {
        if (!speedBytes) speedBytes = 0;
        let str;
        if (speedBytes > 1024 * 1024) str = (speedBytes / (1024 * 1024)).toFixed(2) + 'MB/s';
        else if (speedBytes > 1024) str = (speedBytes / 1024).toFixed(0) + 'KB/s';
        else str = speedBytes.toFixed(0) + 'B/s';
        return str + '';
    },

    size: function(sizeBits) {
        if (!sizeBits) sizeBits = 0;
        let str;
        if (sizeBits > 1024 * 1024 * 1024) str = (sizeBits / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
        else if (sizeBits > 1024 * 1024) str = (sizeBits / (1024 * 1024)).toFixed(1) + 'MB';
        else if (sizeBits > 1024) str = (sizeBits / 1024).toFixed(0) + 'KB';
        else str = sizeBits.toFixed(0) + 'B';

        return str + '';
    },

    elapsedTime: function(seconds) {
        return Math.floor(seconds) + 's';
    },

    remainingTime: function(seconds) {
        return moment.duration(seconds, 'seconds').humanize();
    },

    percentage: function (partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    },
    date: function (value: string | number | Date) : String {
        return moment(value).add(5, 'hours').format('DD/MM/YYYY HH:mm:ss');
    }
};


export default Formatters;