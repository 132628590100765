import moment from 'moment';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

moment.locale('pt-br');

window.onload = () => {
    const rootElement = document.getElementById("root");
    ReactDOM.render(<App />, rootElement);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
