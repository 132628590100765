import React, { useCallback, useEffect, useState } from 'react';
import { Content, Row, Box, Col, SimpleTable } from 'adminlte-2-react';
import { callApi } from '../CallAPI';
//import 'flag-icon-css/css/flag-icon.css';
//import './ModEdit.css';

import UserInfoType from '../modules/UserInfoType';


const Metric = (props) => {
    const params = props.match.params;

    const [metricParsed, setMetricParsed] = useState<UserInfoType.MetricParsed>({} as UserInfoType.MetricParsed);
    const computeGetUser = useCallback(async () => {
        try {
            const response = await callApi('getMetric', { id: params['id'] }) as UserInfoType.MetricParsed;
            console.log(response);
            setMetricParsed(response);
        } catch (error) {
            console.log(error);
        }
    }, [params]);
    useEffect(() => { computeGetUser(); }, [computeGetUser]);

    return <Content title='User' subTitle='Manage user' browserTitle='User'>

        <Row>
            <Col xs={12}>
                <Box title='User Computer login info' type='warning' loaded={Object.keys(metricParsed).length}>

                    {!metricParsed ? null : Object.keys(metricParsed).map((value, index) => {
                        const thisValue = metricParsed[value];
                        if (!thisValue[0])
                            return null;
                        const firstItemKeys = Object.keys(thisValue[0]);
                        const nowColumns = firstItemKeys.map((valuea) => {
                            return { title: valuea, data: valuea };
                        });

                        return (
                            <div key={index}>
                                <h3 style={{ 'marginTop': '33px' }}> {value} </h3>
                                <SimpleTable
                                    columns={nowColumns}
                                    data={thisValue}
                                />
                            </div>
                        );
                    })}

                </Box>
            </Col>
        </Row>

    </Content>;
}



export default Metric;