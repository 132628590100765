import { Box, Col, Content, Row } from 'adminlte-2-react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useCallback, useEffect, useState } from 'react';
import { callApi } from 'CallAPI';
import InfoBoxs from '../components/InfoBoxs';

Highcharts.setOptions({
    lang: {
        months: [
            'Janeiro', 'Fevereiro', 'Março', 'Abril',
            'Maio', 'Junho', 'Julho', 'Agosto',
            'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ],
        weekdays: [
            'Domingo', 'Segunda', 'Terça', 'Quarta',
            'Quinta', 'Sexta', 'Sabado'
        ],
        shortMonths: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Otu", "Nov", "Dez"]
    }
});


const StatsChartOptions = (initialData: any, innerTitle: string, timeBet: number, rounds: number): Highcharts.Options => {
    const ctr = Highcharts.getOptions().colors;
    const now = new Date();
    return {
        series: [{
            type: 'area',
            pointInterval: timeBet * 1000,
            pointStart: Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds()) - (timeBet * rounds) * 1000,
            data: initialData,
            name: 'Ativos',
            color: (ctr) ? ctr[2] : '#90ed7d', //'#434348',
            fillOpacity: 0.3,
            tooltip: {
                valueSuffix: ' Usuários'
            }
        }],
        plotOptions: {
            area: {
                lineWidth: 1,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: true,
                            radius: 5
                        }
                    }
                },
                shadow: false,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                }
            },
            series: {
                marker: {
                    enabled: true
                }
            }
        },
        chart: {
            marginLeft: 40,
            spacingTop: 20,
            spacingBottom: 20,
            events: {
                load: function () {
                    const chart = this;
                    const points = chart.series[0].points;
                    let maxValue;
                    let chosenPoint;

                    points.forEach(function (point, index) {
                        if (point.y) {
                            if (!maxValue || maxValue < point.y) {
                                maxValue = point.y;
                                chosenPoint = point;
                            }
                        }
                    });
                    chosenPoint.update({
                        marker: {
                            enabled: true,
                            radius: 2
                        }
                    });
                }
                /* load: function () {
                    // set up the updating of the chart each second
                    var series = this.series[0];
                    setInterval(function () {
                        var x = (new Date()).getTime(), // current time
                            y = Math.random();
                        series.addPoint([x, y], true, true);
                    }, 1000);
                } */
            }
        },
        title: {
            text: innerTitle,
            align: 'left',
            margin: 0,
            x: 30,
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        time: {
            useUTC: true
        },
        xAxis: {
            type: 'datetime',
            crosshair: true,
            //tickPixelInterval: 150
            events: {
                //setExtremes: syncExtremes
            },
            /* labels: {
                format: '{value}'
            } */
        },
        yAxis: {
            title: {
                text: null
            }
        },
        tooltip: {
            /* positioner: function (this: Tooltip, labelWidth: number, labelHeight: number, point: TooltipPositionerPointObject): PositionObject {
                return {
                    x: this.chart.chartWidth - labelWidth,
                    y: 10
                };
            },
            borderWidth: 0,
            backgroundColor: 'none',
            pointFormat: '{point.y}',
            headerFormat: '',
            shadow: false,
            style: {
                fontSize: '18px'
            }, */
            valueDecimals: 0,
            shared: true
        }
    }
}





//generic stats chart Box
const StatsChart = (props) => {
    const [initialData, setInitialData] = useState<Array<number>>([]);
    const computeFirstData = useCallback(async () => {
        try {
            const response = await callApi('getSessionsCounts', {
                rounds: props.rounds,
                timeBet: props.timeBet,
                duration: props.duration ? props.duration : (5 * 60) + 5,
            });
            console.log(response);
            setInitialData(response);
        } catch (error) {
            console.log(error);
        }
    }, [props.duration, props.rounds, props.timeBet]);
    useEffect(() => { computeFirstData(); }, [computeFirstData]);
    return (
        <Box loaded={initialData.length} {...props}>
            {!!initialData.length &&
                <HighchartsReact
                    highcharts={Highcharts}
                    options={StatsChartOptions(initialData, props.innerTitle ? props.innerTitle : 'Ativos por minuto', props.timeBet, props.rounds)}
                />
            }
        </Box>
    );
}

/* const LiveUserInfo = () => {
   
    useEffect(() => { startEffect(); }, [startEffect]);

    return (
        <MyDescriptionBlock
            upperText={17}
            upperColor='green'
            header="$35,210.43"
            text="Live User Count"
            indication="up"
        />
    )

}
 */
const Stats = (path) => {
    return (<Content title='Status' subTitle='General stats' browserTitle='Status'>
        <Row>
            <InfoBoxs />

            <Col xs={12} ys={6}>
                <StatsChart timeBet={5 * 60 * 2} rounds={144} title='Usuários Online 24h' border />
                <StatsChart timeBet={5 * 60 * 14} rounds={144} title='Usuários Online 7 dias' border />
                <StatsChart timeBet={5 * 60 * 30} rounds={288} title='Usuários Online 30 dias' border />
                <StatsChart timeBet={5 * 60 * 288} rounds={365} duration={60 * 60 * 24} title='Usuários Online 1 Ano' innerTitle='Ativos por dia' border />
            </Col>

            {/* <Infobox
                iconColorOnly={false}
                icon="ion-ios-heart-outline"
                color="green"
                text="Mentions"
                number="92,050"
                progress={20}
                progressText="20% Increase in 30 Days"
            /> */}
        </Row>
    </Content >);
}

export default Stats;