/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useEffect, useState } from 'react';
import { Content, Row, Box, Col, Button } from 'adminlte-2-react';
import { callApi } from 'CallAPI';

import './Mods.css'
import Formatters from '../../utils/Formatters';
import MyTable from '../../components/MyTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Mods = (props) => {
    const [dataModList, setDataModList] = useState<any>([]);

    const responsiveColumns = [
        { title: 'PRODUTO', data: 'modimg', width: '10%', render: data => <img className='mods-img' src={data} height='85px' /> },
        {
            title: '',
            data: 'interProduct',
            render: data =>
                <div id='interProductDiv'>
                    <span className='mods-name'>{data.name}</span>
                    <br></br>
                    <span className='mods-lore'>
                        {Formatters.size(data.size)}
                        {'   •   '}
                        {Formatters.date(data.lastUpdate)}
                        {'       '}
                        <span>
                            <FontAwesomeIcon icon={['fas', 'circle']} className={`text-${data.active ? 'success' : 'danger' }`} />
                            {' ' + (data.active ? '' : 'in') + 'active'}
                        </span>                        
                    </span>                    
                </div>
        },
        { title: 'VERSÃO', data: 'version' }
    ];

    const computeModList = useCallback(async () => {
        try {
            const response = await callApi('listMods', {});
            let aa = [] as any[];
            for (let i = 0; i < response.length; i++) {
                const dml = {
                    modimg: response[i].mini_img_link,
                    interProduct: {
                        name: response[i].clear_name,
                        size: response[i].file_size,
                        wpid: response[i].wp_id,
                        lastUpdate: response[i].update_time,
                        active: response[i].active
                    },
                    version: response[i].version,
                    onClick: () => {
                        props.history.push(dml.clickUrl);
                    },
                    clickUrl: '/mod/' + response[i].id
                }
                aa.push(dml);
            }
            console.log(response);
            setDataModList(aa);
        } catch (error) {
            console.log(error);
        }
    }, [props.history]);

    useEffect(() => { computeModList(); }, [computeModList]);

    return <Content title='Mods' subTitle='Gerencie os Mods' browserTitle='Mods'>
        <Row>
            <Col xs={12} >
                <Box footer={
                    <Button type='success' text='Adicionar Mod' pullRight onClick={() => props.history.push('/add/mod')} />
                }>
                    <MyTable
                        columns={responsiveColumns}
                        data={dataModList}
                        hover={true}
                        {...props}
                    />
                </Box>
            </Col>
        </Row>
    </Content>;
}


export default Mods;