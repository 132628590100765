//import { useCallback, useEffect, useState } from 'react';
import { Content, Row, Box, Col } from 'adminlte-2-react';
import { callApi } from 'CallAPI';
import MyText from '../../components/adminlte/MyText';
//import base64 from 'base-64';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import Modal from '../../components/Modal';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

const AnyErrorMessage = ErrorMessage as any;

const FieldText = (props) => {
    return <Field
        component={TextLTE}
        {...props} />
}

const TextLTE = (props) => {
    const [firstSetTouched, setFirstSetTouched] = useState(false);
    const { field, form } = props;
    const onChange = valor => {
        form.setFieldTouched(field.name);
        form.setFieldValue(field.name, valor.target.value);
    };
    const onBlur = e => {
        form.handleBlur(e);
    };
    const getValue = () => {
        if (field.value) {
            return field.value;
        } else {
            return '';
        }
    };

    const checkAlreadyFulled = useCallback(() => {
        if (!firstSetTouched && field.value) {
            form.setFieldTouched(field.name);
            setFirstSetTouched(true);
        }
    }, [field.name, field.value, firstSetTouched, form]);

    useEffect(() => {
        checkAlreadyFulled();
    }, [checkAlreadyFulled]);


    return (<MyText
        name={field.name}
        onChange={onChange}
        onBlur={onBlur}
        type={form.errors[field.name] && form.touched[field.name] ? 'error' : null}
        help={<AnyErrorMessage name={field.name} />}
        labelPosition='above'
        {...props}
        value={getValue()}
    />);
};


const SignupSchema = Yup.object().shape({
    wp_id: Yup.number()
        .typeError('Invalid number')
        .min(0, 'Positive Nnumber!')
        .required('Required'),
    clear_name: Yup.string()
        .required('Required'),
    name: Yup.string()
        .required('Required'),
    file_name: Yup.string()
        .required('Required'),
});

const ModForm = ({ initialValues, onSubmitOK }: {initialValues: any, onSubmitOK: (values: any) => void}) => {

    const [modalShow, setModalShow] = useState(false);
    const [valuesSubmitting, setValuesSubminitting] = useState({values: {}, setSubmitting: (_state: boolean) => null });

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        setValuesSubminitting({values: values, setSubmitting: setSubmitting});
        setModalShow(true);        
    }

    const handleCancel = () => {
        setModalShow(false);
        valuesSubmitting.setSubmitting(false);
    }
    const handleOK = () => {
        setModalShow(false);
        valuesSubmitting.setSubmitting(false);
        onSubmitOK(valuesSubmitting.values);
    }

    return <>
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={SignupSchema}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
        >
            {props => {
                return (
                    <FormikForm className='Form'>
                        {/* <label htmlFor='active-toggle'>Active</label>
                <Field type='checkbox' name='active-toggle' /> */}
                        <FieldText
                            name='wp_id'
                            label='Wordpress Product ID'
                            placeholder='1000'
                            iconRight='fas-link' />
                        <FieldText
                            name='clear_name'
                            label='Nome Limpo'
                            placeholder='Mapa 123'
                            iconRight='fas-pencil-alt' />
                        <FieldText
                            name='name'
                            label='Nome'
                            placeholder='Mapa 123'
                            iconRight='fas-pen' />
                        <FieldText
                            name='file_name'
                            label='Nome do Arquivo'
                            placeholder='Mapa 123 PRO'
                            iconRight='fas-file-signature' />
                        <button
                            type='submit'
                            className='btn btn-warning btn-block'
                            id='loginButton'
                            disabled={!props.dirty || !props.isValid}
                        >
                            Adicionar
                        </button>
                    </FormikForm>
                );
            }}
        </Formik>
        <Modal show={modalShow} handleCancel={handleCancel} handleOK={handleOK} title={'Confirm Submit'} >
            <p>Are you sure you want to submit?</p>            
        </Modal>
    </>
}


const AddMod = (props) => {
    const onSubmitOK = async (values) => {
        const response = await callApi('insertMod', values);
        props.history.push(`/mod/${response.insertId}`);
    }

    return <Content title='Adicione um Mod' browserTitle='Mods'>
        <Row>
            <Col xs={3}>
                <Box type='warning' title='Insira os dados do Mod a ser adicionado' border>
                    <ModForm
                        initialValues={{}}
                        onSubmitOK={onSubmitOK}
                    />
                </Box>
            </Col>
        </Row>
    </Content>;
}



export default AddMod;