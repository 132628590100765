import { useCallback, useEffect, useRef } from 'react';
import { Content, Box } from 'adminlte-2-react';
import { callApiSocket } from '../CallAPI';
import { XTerm } from 'xterm-for-react'
import { FitAddon } from 'xterm-addon-fit';

import './LiveLog.css';

const fitAddon = new FitAddon();

export interface Message {
    data: string;
    method: string;
}

const LiveLog = (props) => {
    const xtermRef = useRef(null) as any;

    const startEffect = useCallback(async () => {        
        try {
            fitAddon.activate(xtermRef.current.terminal);
            fitAddon.fit();
            await callApiSocket('monitConsoleLogs', {}, (data: Message) => {
                xtermRef.current?.terminal.writeln(data.data);
            });
            console.log('desconectado');
        } catch (error) {
            console.log(error);
            window.alert(error);
        }
    }, []);
    useEffect(() => { startEffect(); }, [startEffect]);

    return <Content title='Live Log' subTitle='See live logs' browserTitle='Live Log'>
        <Box title='Live Server Console Log' border type='danger'>
            <XTerm                
                ref={xtermRef}
                options={{
                    disableStdin: true
                }}               
            />
        </Box>
    </Content>;
}

export default LiveLog;