import AdminLTE, { Sidebar, Navbar } from 'adminlte-2-react';

//import HelloWorld from './pages/HelloWorld';
import Mods from './pages/mod/Mods';
import { configCallApi } from './CallAPI';
import ModEdit from './pages/mod/ModEdit';
import { getLogin, logout, MyLogin } from './MyLogin';
import Users from './pages/user/Users';
import User from './pages/user/User';
import Metric from './pages/Metric';
import Exceptions from './pages/Exceptions';
import Stats from './pages/Stats';
import AddMod from './pages/mod/AddMod';
import LiveLog from './pages/LiveLog';

const gImageUrl = `/48405995_2240049222909820_7612991980708036608_n.jpg`;
//'https://mapaeldorado.com.br/wp-content/uploads/2021/11/55c5ca8d-7ed6-4ad1-9682-11e768a745f8-e1637840486262.jpg'

const { Item, UserPanel, Header } = Sidebar;

const sidebar = [
    <UserPanel
        key='userpanel'
        username='Elvis Felix'
        status='online'
        imageUrl={gImageUrl}
    />,
    <Header key='main-navigation' text='Main Navigation'/>,
    <Item key='home' text='Home' to='/' icon='fa-home' />,
    <Item key='mods' text='Mods' to='/mods' icon='fa-gamepad'>
        <Item key='add-mod' text='Add Mod' to='/add/mod' icon='fa-plus' />
    </Item>,
    <Item key='stats' text='Status' to='/stats' icon='fa-chart-pie' />,
    <Item key='users' text='Users' to='/users' icon='fa-users' />,
    <Item key='exceptions' text="Exceptions" to="/exceptions" icon='fa-exclamation-triangle' />,
    <Item key='live-log' text="Live Log" to="/log" icon='fa-chart-line' />,
];

/* <Item key='datatest' text='Data Test' to='/data-test' icon='fa-database' /> */

const MainInternal = () => {
    return (<AdminLTE title={['Painel', 'Eldorado']} titleShort={['Pa', 'El']} theme='green' sidebar={sidebar}>
        <Navbar.Core>
            <Navbar.Entry icon='fa-bell'>
                <Navbar.NotificationItem text="sss" icon='fa-bed' />
            </Navbar.Entry>
            <Navbar.Entry icon='fa-sign-out-alt' onClick={logout} />
        </Navbar.Core>

        <Mods exact path='/' />
        <Mods exact path='/mods' />
        <AddMod exact path='/add/mod' />
        <ModEdit path="/mod/:id" />

        <Users exact path='/users' />
        <Stats exact path='/stats' />
        <Exceptions exact path='/exceptions' />

        <User exact path='/user/:id' />

        <Metric path="/metric/:id" />

        <LiveLog path='/log' />

    </AdminLTE>);
}

const App = (props) => {
    configCallApi(getLogin, logout);
    if (getLogin()) {
        return <MainInternal />
    } else {
        return <MyLogin />
    }
}
export default App;