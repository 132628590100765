import { v4 as uuid4 } from 'uuid';
import classNames from 'classnames';

const MyTable = (props) => {
    const {
        data, columns, noMargin, condensed, striped, border, hover, responsive,
    } = props;
    const key = uuid4();

    const mapCell = (data, column, rowData, rowIdx) => {
        return (
            <td className='mods-cell' onClick={rowData.onClick} key={`${key}-${rowIdx}-${column.data}`}>
                {(column.render) ? column.render(data, rowData, rowIdx) : data}
            </td>);
    }

    const mappedColumns = data.map((row, rowIdx) => (
        <tr className='mods-column' key={`${key}-${rowIdx}`}>
            {columns.map(col => mapCell(row[col.data], col, row, rowIdx))}
        </tr>
    ));

    let headers;

    const hasHeaders = columns.filter(p => p.title).length > 0;

    if (hasHeaders) {
        headers = columns.map(p => <th className='mods-header' key={`${key}-${p.title}`} style={{ width: p.width }}>{p.title}</th>);
    }

    const table = (
        <table key={key} className={classNames('table',
            {
                'no-margin': noMargin,
                'table-condensed': condensed,
                'table-striped': striped,
                'table-bordered': border,
                'table-hover': hover
            })} >
            {hasHeaders && (
                <thead>
                    <tr>
                        {headers}
                    </tr>
                </thead>
            )}
            <tbody>
                {mappedColumns}
            </tbody>
        </table>
    );
    if (!responsive) {
        return table;
    }
    return <div className="table-responsive">{table}</div>;
}

export default MyTable;