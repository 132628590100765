import { useCallback, useEffect, useState } from 'react';
import { Content, Row, Box, Col, Button, SimpleTable } from 'adminlte-2-react';
import { callApi, callApiSocket } from 'CallAPI';

import './ModEdit.css'
import Formatters from '../../utils/Formatters';

import ProgressBar from '../../components/ProgressBar';
import Dropzone from 'react-dropzone'
import { EditableFields, filterEntryParser } from '../../components/EditableFields';


const apiFilter = {
    "name": { base64: true },
    "clear_name": {},
    "file_name": { base64: true },
    "mini_img_link": {},
    "slogo_img_link": {},
    "tab_img_link": {},
    "file_size": { readOnly: true, size: true },
    "update_time": { readOnly: true, date: true }
}

const postFilter = {
    "post_date": { readOnly: true, date: true },
    "post_date_gmt": { readOnly: true, date: true },
    "post_modified": { readOnly: true, date: true },
    "post_modified_gmt": { readOnly: true, date: true }
}


interface IGetDataUpload {
    step: number;
    steps: number;
    iStep: number;
    iTotalStep: number;
    iPercent: number;
    lastError: string;
}

const UploadModBox = ({
    modId,
    modProcessing,
    atualModVersion,
    setLastUpdate
}: {
    modId: string,
    modProcessing: boolean,
    atualModVersion: number,
    setLastUpdate: (tn: number) => void
}) => {

    const [running, setRunning] = useState(false);
    const [ready, setReady] = useState(false);
    const [progressText, setProgressText] = useState('Error');
    const [progressAll, setProgressAll] = useState(0);
    const [file, setFile] = useState<any>(undefined);
    const [progressError, setProgressError] = useState('');

    const setStepProgress = useCallback((percentage: number, realStep = 0) => {
        setProgressAll(((percentage + (realStep * 100)) / (atualModVersion ? 8 : 3)));
    }, [atualModVersion]);

    const handleProcessing = useCallback(async () => {
        setRunning(true);
        setReady(true);
        try {
            await callApiSocket('getModProcessStats', { id: modId }, (data: IGetDataUpload) => {
                if (data.lastError) {
                    setProgressError(data.lastError);
                }
                switch (data.step) {
                    case 0:
                    case 4:
                        break;
                    case 1:
                        setStepProgress(data.iPercent, data.iStep);
                        setProgressText('Processando mod no servidor primario...');
                        break;
                    case 2:
                    case 3:
                        setProgressText('Fazendo upload do mod para o servidor de download..');
                        setStepProgress(data.iPercent, data.step - 1 + (atualModVersion ? 5 : 1));
                        break;
                }
            });
            setRunning(false);
            setStepProgress(0);
            setLastUpdate(Date.now());
        } catch (error) {
            console.log(error);
            window.alert(error);
        }
    }, [atualModVersion, modId, setLastUpdate, setStepProgress]);

    useEffect(() => {
        if (modProcessing)
            handleProcessing();
        else if (modProcessing === false)
            setReady(true);
    }, [handleProcessing, modProcessing]);

    const onChange = (files: any) => setFile(files[0]);

    const onSubmitUpload = async (e) => {
        try {
            if (!file || !file.name) return;
            const formData = new FormData();
            formData.append('file', file);

            setFile(undefined);
            setRunning(true);
            setProgressText('Fazendo Upload do arquivo para o servidor primario...');

            let ableToContinue = false;

            const uploadProgress = (progressEvent: any) => {
                const percent = Formatters.percentage(progressEvent.loaded, progressEvent.total);
                setStepProgress(percent);
                if (progressEvent.loaded === progressEvent.total) {
                    ableToContinue = true;
                }
            }
            const uploadResult = await callApi('uploadModVersion', { id: modId }, formData, uploadProgress);
            if (ableToContinue && uploadResult?.result === 'success') {
                handleProcessing();
            } else {
                setRunning(false);
                window.alert('Erro ao fazer upload do arquivo');
                console.log('Uploaded size diferrent from total size OR generic upload failed');
            }
        } catch (error) {
            console.error(error);
            window.alert(error);
            setRunning(false);
        }

    }

    return (
        <Box title='Envie uma atualização para o Mod' loaded={ready && atualModVersion >= 0} border footer={
            <Button type='success' text='Fazer Upload e Atualização' pullRight onClick={onSubmitUpload} disabled={!(file && file.name)} />
        }>
            <h4>{progressError}</h4>
            {running ?
                <div>
                    <span>{progressText}</span>
                    <ProgressBar id={'pbUpload'} barPercent={progressAll} text={`Progresso: ${progressAll.toFixed(2)}%`} />
                </div>
                :
                <Dropzone accept={['.zip', '.scs']} multiple={false} onDrop={onChange}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                <p>Drope o arquivo de update do mod aqui</p>
                            </div>
                            {!!file && <aside>
                                <h4>Arquivo escolhido</h4>
                                <ul>{file.name + ' - ' + Formatters.size(file.size)}</ul>
                            </aside>}
                        </section>
                    )}
                </Dropzone>}
        </Box>);
}




const ModEdit = (props) => {
    const params = props.match.params;

    const [apiResponse, setApiResponse] = useState<any>([]);
    const [atualModVersion, setAtualModVersion] = useState(-1);
    const [lastUpdate, setLastUpdate] = useState(Date.now());
    const computeGetMod = useCallback(async () => {
        try {
            const response = await callApi('getMod', { id: params['id'] });
            console.log(response);

            if (response?.apiData?.version !== undefined)
                setAtualModVersion(response.apiData.version);

            !!response.apiData && (response.apiData = filterEntryParser(response.apiData, apiFilter, false));
            !!response.postData && (response.postData = filterEntryParser(response.postData, postFilter, false));

            setApiResponse(response);
        } catch (error) {
            console.log(error);
        }
    }, [params]);
    useEffect(() => { computeGetMod(); }, [computeGetMod, lastUpdate]);

    return <Content title='Gerencie o Mod' browserTitle='Mods'>
        <Row>
            <Col xs={12}>
                <UploadModBox modId={params['id']} modProcessing={apiResponse.modProcessing} atualModVersion={atualModVersion} setLastUpdate={setLastUpdate} />
            </Col>
            {!Object.keys(apiResponse).length ? null :
                <Col xs={12}>
                    {!!apiResponse.apiData && <Box title='Api' border type='success'>
                        <EditableFields data={apiResponse.apiData} prefixKey='api' />
                    </Box>}
                    {!!apiResponse.postData &&
                        <Box title='Wordpress' border type='info' collapsed collapsable>
                            <EditableFields data={apiResponse.postData} prefixKey='post' />
                        </Box>}
                    {!!apiResponse.metaData &&
                        <Box title='Meta Wordpress' border type='warning' collapsed collapsable>
                            <SimpleTable
                                columns={Object.keys(apiResponse.metaData).map((valuea) => {
                                    return { title: valuea, data: valuea };
                                })}
                                data={[apiResponse.metaData]} />
                        </Box>
                    }
                </Col>}
        </Row>
    </Content>;
}



export default ModEdit;