import EasyEdit from 'react-easy-edit';
import base64 from 'base-64';

import Formatters from '../utils/Formatters';

export interface EditableFieldsData {
    readOnly?: boolean;
    base64?: boolean;
    name: string;
    data: any;
    type?: string;
}

export function EditableFields({ data, prefixKey }: { data: EditableFieldsData[]; prefixKey: string; }) {

    const save = (value) => {
        //alert(value);
    };

    return (<div className='editable-fields'>

        {data.map((field) => {
            let finalData;
            try {
                finalData = field.base64 && field.data ? base64.decode(field.data) : field.data;
            } catch (error) {
                finalData = field.data;
            }

            return (<div key={prefixKey + '-' + field.name}>
                <strong>{field.name}:&nbsp;&nbsp;&nbsp;</strong>
                {field.readOnly && false ?
                    <strong>
                        {finalData}
                    </strong> :
                    <EasyEdit
                        type={field.type ? field.type : "text"}
                        onSave={save}
                        saveButtonLabel="Save"
                        cancelButtonLabel="Cancel"
                        attributes={{ name: "awesome-input", id: 1 }}
                        value={finalData}
                        allowEdit={!field.readOnly} />}

            </div>);
        })}
    </div>);
}


export function filterEntryParser(dataArr: any, filter: any, defaultEditable: boolean) {
    const final: EditableFieldsData[] = [];
    Object.entries(dataArr).forEach((data: any) => {
        const filterEntry = filter[data[0]];
        const finalEntry: EditableFieldsData = {
            name: data[0],
            data: data[1]
        };
        if (filterEntry) {
            if (filterEntry.base64) finalEntry.base64 = true;
            if (filterEntry.size) finalEntry.data = Formatters.size(data[1]);
            if (filterEntry.date) finalEntry.data = Formatters.date(data[1]);
        }
        if ((!filterEntry && !defaultEditable) || filterEntry?.readOnly) finalEntry.readOnly = true;
        final.push(finalEntry);
    });
    return final;
}