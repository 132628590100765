import React, { useCallback, useEffect, useState } from 'react';
import { Content, Row, Box, Col } from 'adminlte-2-react';
import base64 from 'base-64';
import { callApi } from 'CallAPI';
//import 'flag-icon-css/css/flag-icon.css';
//import './ModEdit.css';

import UserInfoType from '../modules/UserInfoType';
import ClickableDatatables from '../components/ClickableDatatables';

const exTbColumns: {
    title: string;
    data: string;
    render?: any;
}[] =
    [
        { title: 'ID', data: 'id' },
        //{ title: 'Token', data: 'token' },
        { title: 'Last User', data: 'last_user' },
        { title: 'Date', data: 'date' },
        { title: 'Original Date', data: 'original_date' },
        {
            title: 'IP', data: 'ip', /* render: (data, _type, row, meta) => {
                const geoipParsed = JSON.parse(row['geoIp']);
                return '<a class="clickable-table-a geoip-ip" onclick="event.preventDefault();" href="/exception/' + row['id'] + '">' +
                    '<span class="flag-icon flag-icon-' + geoipParsed?.country?.toLowerCase() + '"></span><span>' + data + '</span></a>';
            } */
        },
        { title: 'EX Stack', data: 'ex_stack' },
        {
            title: 'Extra Data', data: 'extra_data',/*  render: (data, _type, row, meta) => {
                let newData = data;
                if (data && isNaN(data))
                    newData = base64.decode(data);
                return '<a class="clickable-table-a" onclick="event.preventDefault();" href="/exception/' + row['id'] + '">' + newData + '</a>';
            } */
        },
        { title: 'Last Error', data: 'last_error' },
        { title: 'Source', data: 'source' },
        { title: 'Version', data: 'version' },
    ];

const Exceptions = (props) => {
    const params = props.match.params;

    const [userExceptions, setUserExceptions] = useState<UserInfoType.UserExceptions[]>([]);
    /*  const computeGetUser = useCallback(async () => {
         try {
             const response = await callApi('getExceptions', { id: params['id'] }) as UserInfoType.UserExceptions[];
             console.log(response);
             setUserExceptions(response);
         } catch (error) {
             console.log(error);
         }
     }, [params]);
     useEffect(() => { computeGetUser(); }, [computeGetUser]); */

    return <Content title='User' subTitle='Manage user' browserTitle='User'>

        <Row>
            <Col xs={12}>
                <Box collapsable title='Exceptions' border type='danger' /* loaded={Object.keys(userExceptions).length} */>
                    <ClickableDatatables
                        columns={/* [{ title: 'ID' }] */exTbColumns}
                        data={userExceptions}
                        history={props.history}
                        redirectBaseUrl='/exception/'
                        rowClickName='id'
                        ignoreEmpty={true}
                        options={{
                            scrollX: true,
                            scrollCollapse: true,
                            
                            serverSide: true,
                            //columns: null,
                            iDisplayLength: 10,
                            processing: true,

                            //async: true,

                            ajax: (data, callback, settings) => {
                                new Promise<void>(async (resolve, reject) => {
                                    // ... do stuff
                                    //const out = [] as any;
                                    const responseData = await callApi('getExceptions', data) as any;
                                    callback({
                                        draw: data.draw,
                                        data: responseData.data,
                                        recordsTotal: responseData.recordsTotal,
                                        recordsFiltered: responseData.recordsFiltered
                                    });
                                    callback(responseData);
                                    resolve();
                                });
                            },

                            /* ajax: async function (data, callback, settings) {

                    }, */

                            /* lengthChange: true,
                            iDisplayLength: 10,
                            scrollX:        true,
                            scrollCollapse: true,

                            //processing: true,
                            serverSide: true,

                            oLanguage: {
                        sProcessing: 'Processando...'//"<img src='../dist/img/loading_spinner.gif' />"
                            },

                    ajax: function ( data, callback, settings ) {
                                const out = [] as any;

                    for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                        out.push([i + '-1', i + '-2', i + '-3', i + '-4', i + '-5']);
                                }

                    setTimeout( function () {
                        callback({
                            draw: data.draw,
                            data: out,
                            recordsTotal: 5000000,
                            recordsFiltered: 5000000
                        });
                                }, 50 );
                            },

                    sAjaxSource: 'http://127.0.0.1:3000/statistic?schemename=abc',
                    fnServerData: function (sSource, aoData, fnCallback) {
                        /*  $.ajax({
                             "dataType": 'json',
                             "type": "POST",
                             "url": sSource,
                             "data": aoData,
                             "success": fnCallback
                         }); *-/
                     },
                     sDom: "<'row-fluid'<'span6'l><'span6'f>r>t<'row-fluid'<'dataTables_wrapper'ip>>",
                     sPaginationType: "full_numbers",//"full_numbers",
                     aoColumns: [
                         { "sName": "base", "bSortable": false },//0
                         { "sName": "base1", "bSortable": false }
                     ] */
                        }} />
                </Box>
            </Col>
        </Row>

    </Content>;
}



export default Exceptions;