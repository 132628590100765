import Login from './components/LoginAdminLTE';
import { callApi } from 'CallAPI';

export const handleLogin = async (values) => {
    if (values.user && values.password) {
        try {
            const response = await callApi('login', values);
            if (response.result) {
                localStorage.setItem('adminToken', response.tk);
                window.location.reload();
            } else {
                window.alert('Error: ' + response.message);
            }           
        } catch (error) {
            console.error(error);
        }
    }
}

export const getLogin = () => {
    return localStorage.getItem('adminToken');
}

export const logout = () => {
    localStorage.removeItem('adminToken');
    window.location.reload();
}

export const MyLogin = () => {
/*     const history = useHistory();
    const handleReload = useCallback(() => history.push('/'), [history]); */

    const loginInitial = {
        user: '',
        password: ''
    };
    return <Login
        handleSubmit={handleLogin}
        initialValues={loginInitial}
    />
}